import * as React from 'react';
import {
    CustomRoutes,
    defaultTheme,
    mergeTranslations,
    //Resource,
    useStore, //uus
    StoreContextProvider, //uus
    localStorageStore, //uus
} from 'react-admin';
import { Resource } from '@react-admin/ra-rbac';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
import { addEventsForMutations } from '@react-admin/ra-audit-log';
import CssBaseline from '@mui/material/CssBaseline';
import {
    raTreeLanguageEnglish,
    raTreeLanguageFrench,
} from '@react-admin/ra-tree';
import {
    raTourLanguageEnglish,
    raTourLanguageFrench,
} from '@react-admin/ra-tour';
import { Route } from 'react-router';

import { createTheme } from '@mui/material';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import finnishMessages from './i18n/fi';
import { getThemes } from './layout/themes';
import dataProvider from './dataProvider';
import { QueryClient } from 'react-query';
import Configuration from './configuration/Configuration';

import product from './resources/product';
import subscription from './resources/subscription';
import school from './resources/school';
import user from './resources/user';
import teacher from './resources/user/teacher';
import pupil from './resources/user/pupil';
import student from './resources/user/student';
import announcement from './resources/announcement';

import { withLifecycleCallbacks } from 'react-admin';
import { productLifecycleCallbacks } from './resources/product';

const messages = {
    en: mergeTranslations(
        englishMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    fr: mergeTranslations(
        frenchMessages,
        raTreeLanguageFrench,
        raTourLanguageFrench
    ),
    fi: mergeTranslations(
        finnishMessages,
        raTreeLanguageEnglish, //täytyy selvittää löytyykö suomea
        raTourLanguageEnglish
    ),
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10 * 60 * 1000, // 1 minutes
        },
    },
});

const i18nProvider = buildI18nProvider(messages, 'fi');

const enhancedDataProvider = addEventsForMutations(
    withLifecycleCallbacks(dataProvider, [productLifecycleCallbacks]),
    authProvider
);

const store = localStorageStore(undefined, 'InfraAdmin'); //uus

const App = () => {
    const theme = createTheme(defaultTheme);
    const { darkTheme, lightTheme } = getThemes(theme);

    return (
        <Admin
            title=""
            store={store} //uus
            dataProvider={enhancedDataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            queryClient={queryClient}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/oidc-login/:code" element={<Login />} />
            </CustomRoutes>
            <CssBaseline />
            <Resource name="product" {...product} />
            <Resource name="subproduct" />
            <Resource name="subscription" {...subscription} />
            <Resource name="school" {...school} />
            <Resource name="group" />
            <Resource name="user" {...user} />
            <Resource name="teacher" {...teacher} />
            <Resource name="pupil" {...pupil} />
            <Resource name="student" {...student} />
            <Resource name="announcement" {...announcement} />
            <Resource name="userlicense" />
            <Resource name="usergroup" />
            <Resource name="logevent" />
        </Admin>
    );
};

export default App;
