import * as React from 'react';
import { FC } from 'react';
//import { useFormState } from 'react-final-form';
import { useWatch } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import TextField from '@mui/material/TextField';
import lodash from 'lodash';

interface Props {
    source: string;
    resource?: string;
    //fullWidth?: boolean;
}

const CustomTextField: FC<Props> = ({ source, resource='', ...props }) => {

    const translate = useTranslate();
    //const { values } = useFormState();
    const val = useWatch({ name: source });
    //console.log(values);
    const value =
        val != undefined
            ? val
                : '';
    /* const value =
        lodash.get(props['record'], source) != undefined
            ? lodash.get(props['record'], source) 
            : lodash.get(values, source) != undefined
                ? lodash.get(values, source)
                : ''; */
     

    //console.log(translate('resources.'+resource+'.'+source));
    //console.log(translate(''+resource+'.'+source));
    //console.log(translate('resources.'+resource+'.'+source.replace("private", "Private")));

    return (
        <TextField
          id={source}
          label={translate('resources.'+resource+'.fields.'+source)}
          multiline={true}
          value={value}
          className="CustomTextField"
          fullWidth
          {...props}
        />
    );
};

export default CustomTextField;