import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, SavedQueriesList } from 'react-admin';
//import { SavedQueriesList } from '@react-admin/ra-preferences';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

import SubscriberIcon from '@mui/icons-material/Domain'
import ProductIcon from '@mui/icons-material/LibraryBooks';
import StatusIcon from '@mui/icons-material/CheckCircle';

import CustomFilterList from '../../controls/CustomFilterList';
import CustomFilterSearch from '../../controls/CustomFilterSearch';

//import segments from '../segments/data';

const PupilListAside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            order: -1,
            flex: '0 0 15em',
            mr: 2,
            mt: 8,
            alignSelf: 'flex-start',
        }}
    >
        <CardContent sx={{ pt: 1 }}>
            <FilterLiveSearch source="q" variant='outlined' />
            <SavedQueriesList />
            <CustomFilterList
                url="extralist/pupil/statuses"
                label="resources.user.filters.status"
                source="group_status"
                icon={<StatusIcon />}
            />
            <CustomFilterSearch
                url="extralist/schools"
                label="resources.user.filters.schools"
                source="parent_group"
                variant="filled"
                storeKey="resources.pupil.filters.schools"
                //storeKeyToClear="store.subscription.subscriber_users"
                icon={<SubscriberIcon />}
            />
            {/* <CustomFilterSearch
                url="extralist/school/##/groups"
                label="resources.user.filters.groups"
                source="parent_group"
                variant="filled"
                storeKey="resources.pupil.filters.groups"
                //storeKeyToClear="store.subscription.subscriber_users"
                icon={<SubscriberIcon />}
            /> */}
        </CardContent>
    </Card>
);

export default PupilListAside;
