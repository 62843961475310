import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import CustomStatusField from '../../controls/CustomStatusField';
import TeacherListAside from './TeacherListAside';
import get from 'lodash/get';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';
import CustomLogAsTeacherButton from '../../controls/CustomLogAsTeacherButton';

const teacherFilters = [
    <SearchInput source="q" alwaysOn />,
];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const TeacherList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = ['public.firstname', 'public.lastname', 'username', 'last_login', 'registered'];
    
    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? teacherFilters : undefined}
            sort={{ field: 'guid', order: 'ASC' }}
            filter={{}}
            perPage={25}
            aside={<TeacherListAside />}
            actions={<ListActions />}
        >
            
            <DatagridConfigurable
                //optimized
                omit={['guid']}
                rowClick="edit"
                //rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}}
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                {/* {columns} */}
                <TextField source="guid" key="guid" sortable={sortable.includes('guid')} />
                <TextField source="public.firstname" key="firstname" sortable={sortable.includes('public.firstname')} />
                <TextField source="public.lastname" key="lastname" sortable={sortable.includes('public.lastname')} />
                <TextField source="username" key="username" sortable={sortable.includes('username')} />
                <DateField source="last_login" key="last_login" showTime={true} locales="fi-FI" sortable={sortable.includes('last_login')} />
                <DateField source="registered" key="registered" showTime={false} locales="fi-FI" sortable={sortable.includes('registered')} />
                <CustomLogAsTeacherButton sortable={sortable.includes('log_as_teacher')} /> 
                <FunctionField label="Lähde" render={record =>  get(record,'services',[]).map(service => !['opiskelija','opepalvelu','oppilaspalvelu'].includes(service) ? <span key={`service_${service}`} style={{textDecoration: 'none', backgroundColor: '#afbec5',fontSize:12,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}} >{`${service}`}</span> : '')}/>
            </DatagridConfigurable>
        </List>
    );
};

export default TeacherList;
