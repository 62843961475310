import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    DateTimeInput,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box } from '@mui/material';


import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
} from '../controls';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
} from '@mui/icons-material';

import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,  
} from 'ra-input-rich-text';
 //import { RichTextInput } from 'ra-input-rich-text';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const AnnouncementEdit = () => {

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />} >
                <FormTab label="resources.announcement.tabs.details" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextInput resource="announcement" source="name" validate={[required()]} fullWidth />
                                {/* <TextInput resource="announcement" source="public.message" validate={[required()]} fullWidth /> */}
                                <RichTextInput resource="announcement" source="public.message" validate={[required()]} />
                            </Grid>

                            <Grid item xs={2} md={2}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><DateTimeInput resource="announcement" source="valid_from" /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><DateTimeInput resource="announcement" source="valid_until" /></Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomRadioButtonGroupInput resource="announcement" source="status" url="extralist/announcement/statuses" parse={v => parseInt(v)} /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="announcement" source="public.languages" url="extralist/announcement/languages" /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="announcement" source="public.applications" url="extralist/announcement/applications" /></Box>
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} md={12}>
                                <TextInput resource="announcement" source="public.tags" fullWidth />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}>
                                        <CustomSelectArrayInput resource="announcement" source="public.announcement_type" url="extralist/announcement/types" fullWidth />
                                        <CustomSelectArrayInput resource="announcement" source="public.categories" url="extralist/product/categories" fullWidth />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}>
                                        <CustomSelectArrayInput resource="announcement" source="public.types" url="extralist/school/types" fullWidth />
                                        <CustomSelectArrayInput resource="announcement" source="public.typegroups" url="extralist/school/type_groups" fullWidth />
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default AnnouncementEdit;