import * as React from 'react';
import { useCallback } from 'react';

import { useDataProvider, FilterList, FilterListItem, useListFilterContext, useStore, useTranslate, ResettableTextField} from 'react-admin';
import TextField from '@mui/material/TextField';
import { ChangeEvent, memo, useMemo } from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


export interface CustomFilterSearchProps {
    source?: string;
    optionValue?: string;
    optionText?: string;
    url: string;
    label: string;
    icon: any;
    variant?: 'filled' | 'outlined';
    storeKey?: string;
    minLength?: number;
    storeKeyToClear?: string;
}

interface ResItem {
    item_id: any;
    item_title: any;
}

const CustomFilterSearch = memo((props: CustomFilterSearchProps) => {

    const {
        source = '',
        variant,
        optionValue = 'item_id',
        optionText = 'item_title',
        url,
        label,
        icon,
        storeKey = 'key',
        minLength = 3,
        storeKeyToClear = "",
    } = props;

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [customChoices, setCustomChoices] = useStore(storeKey+"_result", []);
    const [userQuery, setUserQuery] = useStore(storeKey+"_q", "");
    const { filterValues, setFilters } = useListFilterContext();

    const {resource} = useListFilterContext();
    const [close, setClose] = useStore(`${resource}.${source}.mode`, "");

    //tähän täytynee virittää useamman puhdistus???
    const [customChoicesToClear, setCustomChoicesToClear] = useStore(storeKeyToClear+"_result", []);
    const [userQueryToClear, setUserQueryToClear] = useStore(storeKeyToClear+"_q", "");

    const sendQuery = (query: string) => {
        dataProvider
        .getAny(url, "filter=" + JSON.stringify({ "q": query}))
        .then(({ data }) => {
            console.log(data);
            setCustomChoices(data);
        })
        .catch(error => {
            console.log(error);
        });
    };
    const delayedQuery = useCallback(debounce(q => sendQuery(q), 500), []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.value.length > minLength-1) {
            console.log(event.target.value);
            setUserQuery(event.target.value);
            delayedQuery(event.target.value);
            setCustomChoicesToClear([]);
            setUserQueryToClear("");
        } else if (event && event.target && event.target.value) {
            console.log("else if");
            setUserQuery(event.target.value);
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null);
            setCustomChoices([]);
        } else {
            console.log("else");
            setUserQuery("");
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null);
            setCustomChoices([]);
        }
    };

    const handleOnClick: any = () => {
        close !== '' ? setClose('') : setClose('_close');
    };

    return (
        <div style={{display: 'grid'}}>
            <Button onClick={handleOnClick} className={clsx(`close_button${close}`)}>
                { close === '' ? (<ArrowDropDownIcon />) : (<ArrowRightIcon />) }
            </Button>
            <FilterList 
                label={`${label}`} 
                icon={icon}
                className={clsx(`OopFilterList${close}`, `oop-filterlist-${source}${close}`)}>
                <ResettableTextField
                    resettable
                    helperText={false}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                    }}
                    value={userQuery}
                    onChange={handleChange}
                    size="small"
                    {...(variant === 'outlined'
                        ? { variant: 'outlined', label: translate(label+'.search') }
                        : {
                            placeholder: translate(label+'.search'),
                            label: false,
                            hiddenLabel: true,
                        })}
                />
                {customChoices && customChoices.map(choice => (
                    <FilterListItem
                        label={choice[optionText]}
                        key={choice[optionValue]}
                        value={JSON.parse(
                            `{"${source}": "${choice[optionValue]}"}`
                        )}
                    />
                ))}
            </FilterList>
        </div>
    );
});

export default CustomFilterSearch;
