import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    ReferenceField,
    FunctionField,
    DateField,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box } from '@mui/material';


import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
    CustomUserLicenseByUserList,
    CustomUserSchoolList,
} from '../../controls';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
 } from '@mui/icons-material';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const PupilEdit = () => {

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />} >
                <FormTab label="resources.pupil.tabs.profile" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextInput resource="pupil" source="public.firstname" validate={[required()]} fullWidth />
                                <TextInput resource="pupil" source="public.middlename" fullWidth />
                                <TextInput resource="pupil" source="public.lastname" validate={[required()]} fullWidth />
                                <TextInput resource="pupil" source="public.code" validate={[required()]} fullWidth />
                                <CustomSelectInput resource="pupil" source="status" url="extralist/pupil/statuses" parse={v => parseInt(v)} fullWidth />
                                
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    Kirjautunut: <DateField resource="pupil" source="last_login" showTime />
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    Luotu:
                                    <ReferenceField reference="user" source="author.creator">
                                        <FunctionField render={record => `${record.public.firstname} ${record.public.lastname}, `} />
                                    </ReferenceField>
                                    <DateField resource="pupil" source="author.created" showTime />
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    Muokattu:
                                    <ReferenceField reference="user" source="author.modifier">
                                        <FunctionField render={record => `${record.public.firstname} ${record.public.lastname}, `} />
                                    </ReferenceField>
                                    <DateField resource="pupil" source="author.modified" showTime />
                                </Box>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.pupil.tabs.schools_and_groups" icon={<LibraryBooks />} path="koulut_ja_ryhmat">
                    
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserSchoolList disableSyncWithLocation storeKey='userSchoolList' resource='usergroup' filter={{type:2}} customFilters={['user_guid']} showExtra={['']} hideFields={['name']} bulkActionButtons={false}><span /></CustomUserSchoolList>
                        </Grid>
                    </Grid>
                    
                </FormTab>
                <FormTab label="resources.pupil.tabs.materials" icon={<LibraryBooks />} path="kaytossa">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomUserLicenseByUserList resource='userlicense' disableSyncWithLocation customFilters={['user_guid']} showExtra={[]} hideFields={[]} sort={{ field: 'product.name', order: 'ASC' }} bulkActionButtons={<CustomBulkDeleteButton />}><span /></CustomUserLicenseByUserList>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.pupil.tabs.merge" icon={<LibraryBooks />} path="yhdistaminen">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                
                            </Grid>
                        </Grid>
                    
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default PupilEdit;