import * as React from 'react';
import {
    BooleanField,
    CreateButton,
    FilterButton,
    List,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';
import CustomStatusField from '../controls/CustomStatusField';
import CustomUrlButton from '../controls/CustomUrlButton';
import ProductListAside from './ProductListAside';
import MovingIcon from '@mui/icons-material/Moving';
import get from 'lodash/get';
import CustomPreviewMaterialButton from '../controls/CustomPreviewMaterialButton';

const productFilters = [
    <SearchInput source="q" alwaysOn />,
];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            <CreateButton />
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const ProductList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = ['name', 'public.category', 'public.materialstorage.isbn', 'private.tuoterekisteri_data.kantanumero', 'private.tuoterekisteri_data.saatavuus', 'is_group', 'status'];
    
    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? productFilters : undefined}
            sort={{ field: 'public.category', order: 'ASC' }}
            perPage={25}
            aside={<ProductListAside />}
            actions={<ListActions />}
        >
            
            <DatagridConfigurable
                //optimized
                omit={['guid']}
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                {/* {columns} */}
                <TextField source="guid" key="guid" sortable={sortable.includes('guid')} />
                <TextField source="public.materialstorage.isbn" key="public.materialstorage.isbn" sortable={sortable.includes('public.materialstorage.isbn')} />
                <TextField source="private.tuoterekisteri_data.kantanumero" key="private.tuoterekisteri_data.kantanumero" sortable={sortable.includes('private.tuoterekisteri_data.kantanumero')} />
                <TextField source="name" key="name" sortable={sortable.includes('name')} />
                <TextField source="public.category" key="public.category" sortable={sortable.includes('public.category')} />
                <TextField source="private.tuoterekisteri_data.saatavuus" key="private.tuoterekisteri_data.saatavuus" sortable={sortable.includes('private.tuoterekisteri_data.saatavuus')} />
                <BooleanField source="is_group" key="is_group" sortable={sortable.includes('is_group')} />
                <CustomStatusField source="status" key="status" url="extralist/product/statuses" sortable={sortable.includes('status')} />
                <CustomPreviewMaterialButton sortable={sortable.includes('status')} />
                {/* Tähän täytyy vielä tehdä materiaalin esikatselunappi */}
                {/* <CustomUrlButton label={translate('resources.subscription.name', { smart_count: 2 })} gotoexternalurl={true} icon={<MovingIcon style={{fontSize: 'large'}} />} url={`/#/subscription?filter=%7B"product_guid"%3A"#guid#"%7D&order=DESC&page=1&perPage=25&sort=created`} urlparams={['guid']} /> */}
                <FunctionField source="id" key="guidlink" render={record =>  get(record,'id','') !== '' ? <a onClick={(e) => {e.stopPropagation();}} style={{textDecoration: 'none', backgroundColor: '#afbec5',fontSize:12,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}} title={`${get(record,'id')}`} href={`#/subscription?filter=%7B"product_guid"%3A"${get(record,'id')}"%7D&order=DESC&page=1&perPage=25&sort=created`}>Tilaukset</a> : ''} sortable={sortable.includes('orders')} />
            </DatagridConfigurable>
        </List>
    );
};

export default ProductList;
