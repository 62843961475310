import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import CustomStatusField from '../controls/CustomStatusField';
import SchoolListAside from './SchoolListAside';
import SchoolFullNameField from './SchoolFullNameField';
import get from 'lodash/get';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';

const schoolFilters = [
    <SearchInput source="q" alwaysOn />,
];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const SchoolList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = ['name', 'public.address', 'public.zip_code', 'public.city', 'public.code', 'public.type'];
    
    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? schoolFilters : undefined}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ type: '2'}}
            perPage={25}
            aside={<SchoolListAside />}
            actions={<ListActions />}
            debounce={1000}
        >
            
            <DatagridConfigurable
                //optimized
                omit={['guid']}
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                {/* {columns} */}
                <TextField source="guid" key="guid" sortable={sortable.includes('guid')} />
                <SchoolFullNameField source="name" key="name" createLink={false} services={true} sortable={sortable.includes('name')} />
                <TextField source="public.address" key="public.address" sortable={sortable.includes('public.address')} />
                <TextField source="public.zip_code" key="public.zip_code" sortable={sortable.includes('public.zip_code')} />
                <TextField source="public.city" key="public.city" sortable={sortable.includes('public.city')} />
                <TextField source="public.code" key="public.code" sortable={sortable.includes('public.code')} />
                <TextField source="public.type" key="public.type" sortable={sortable.includes('public.type')} />
                <FunctionField source="private.services.crm.crmGUID" key="private.services.crm.crmGUID" render={record =>  get(record,'private.services.crm.crmGUID','') !== '' ? <a onClick={(e) => {e.stopPropagation();}} style={{textDecoration: 'none', backgroundColor: '#afbec5',fontSize:12,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}} target='_blank' title={`${get(record,'private.services.crm.crmGUID')}`} href={`https://oppimisencrm.crm4.dynamics.com/main.aspx?appid=b68a0d15-8769-4bc0-8999-f949f7404bbb&pagetype=entityrecord&etn=account&id=${get(record,'private.services.crm.crmGUID')}`}>crm</a> : ''} sortable={sortable.includes('private.services.crm.crmGUID')} />
            </DatagridConfigurable>
        </List>
    );
};

export default SchoolList;
