import * as React from 'react';
import { SxProps, Typography, Avatar, StepLabel } from '@mui/material';
import { memo } from 'react';

import { FieldProps, useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { Product } from './types';
import lodash from 'lodash';
import ProductIcon from '@mui/icons-material/Collections';


interface Props {
    createLink?: boolean;
    useIcon?: boolean;
    source: string;
    useLabel?: boolean;
    label?: string;
    sortable?: boolean;
}

const linkUrl = (g) => {
    let baseUrl = "/product";
    return baseUrl + '/' + g;
}

const handleOnClik = (e, g) => {
    e.stopPropagation();
//    window.location.href=linkUrl(g);
}

const ProductFullNameField = (props: Props) => {
    const translate = useTranslate();
    const product = useRecordContext();
    const record = product[props.source];

    return record ? (
        props.createLink ? 
            <>
            {props.useLabel && <StepLabel>{props.label ? translate(props.label, { smart_count: 1 }) : ""}</StepLabel>}
            <Link to={linkUrl(record.guid)} onClick={(e) => handleOnClik(e, record.guid)}>
                    <Typography
                        variant="body2"
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        component="div"
                    >
                        {props.useIcon ? <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}><ProductIcon/></Avatar> : null}
                        {record.name}
                    </Typography>
            </Link></> : 
            <>
            {props.useLabel && <StepLabel>{props.label ? translate(props.label, { smart_count: 1 }) : ""}</StepLabel>}
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
            >
                {props.useIcon ? <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}><ProductIcon/></Avatar> : null}
                {record.name}
            </Typography></>
    ) : null;
};

ProductFullNameField.defaultProps = {
    source: '',
    label: 'resources.product.name',
    createLink: false,
    useIcon: false,
    useLabel: false,
    sortable: false,
};

export default memo<Props>(ProductFullNameField);
