import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Edit, SimpleForm, SimpleShowLayout, List, Datagrid, TextField, TextInput, SearchInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore, useRecordContext, useResourceContext, BooleanInput, BooleanField } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import CustomSelectInput from './CustomSelectInput';
import MovingIcon from '@mui/icons-material/Moving';
import { Grid, Box, Typography } from '@mui/material';
import CustomUserGroupList from './CustomUserGroupList';
import user from '../user';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
    storeKey?: string;
}

const GroupEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    //console.log("CustomUserGroupList Edit", record, resource);
    return (
        <Edit
            resource={resource}
            id={record.id}
            /* disable the app title change when shown */
            title=" "
            className='cgl-dg-edit'
        >
            <SimpleForm className='cgl-dg-edit-form' toolbar={false}>
                <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Perustiedot</Typography>
                        <Typography variant="body1" paragraph>
                            Nimi: {record.group.name}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Ryhmät</Typography>
                        <CustomUserGroupList disableSyncWithLocation storeKey='customUserGroupList' resource='usergroup' filter={{type:4}} customFilters={['parent_guid','user_guid']} showExtra={['']} hideFields={['']} bulkActionButtons={false}><span /></CustomUserGroupList>
                    </Grid>
                </Grid>

            </SimpleForm>
        </Edit>
    );
};

const CustomUserSchoolList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    //console.log(parent);
    var filter = {};

    if (props.filter)
        filter = props.filter;

    if (props.customFilters?.includes("user_guid"))
        filter["user_guid"] = parent.record.guid;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={props.bulkActionButtons} className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.fields.name')} sortable={props.sortable} />}
                <GroupEdit />
            </Datagrid>
        </List>
    );
};

CustomUserSchoolList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
    storeKey: 'customUserSchoolList',
};

export default CustomUserSchoolList;
